import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoVoox from "../svg/logoVoox"

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin-top: 70px;
`

const Logo = styled.div`
  width: 414px;
  max-width: 90%;
`

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
`

const Video = styled.div`
  width: 942px;
  height: 530px;
  max-width: 95%;
  margin-top: 60px;
  margin-bottom: 120px;
  position: relative;

  @media (max-width: 600px) {
    width: 471px;
    height: 265px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const AwardPage = () => (
  <Layout>
    <SEO title="今日から始めるスマートな10分習慣 | VOOX" />
    <Main>
      <Logo>
        <LogoVoox />
        <Title>今日から始めるスマートな10分習慣</Title>
      </Logo>
      <Video>
        <iframe
          src="https://player.vimeo.com/video/557805631?title=0&byline=0&portrait=0"
          width="942"
          height="530"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Video>
    </Main>
  </Layout>
)

export default AwardPage
